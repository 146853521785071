import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { NavLink } from "reactstrap";
/* eslint-disable */
import ErrorPage from "../pages/error";
/* eslint-enable */
import "../styles/theme.scss";
import Login from "../pages/login";
import Register from "../pages/register";
import VerifyEmail from "../pages/login/VerifyEmail";
import ResetLink from "../pages/login/ResetLink";
import ResetPassword from "../pages/login/ResetPassword";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import MSGIcon from "@mui/icons-material/Telegram";
import APIServer from "../utils/ServerApi";
import Referral from "../pages/register/Refer";

const PrivateRoute = lazy(() => import("./PrivateRoute"));
const LayoutComponent = lazy(() => import("../components/Layout"));

// https://seeklogo.com/images/T/telegram-logo-E89B56AD97-seeklogo.com.png

const CloseButton = ({ closeToast }) => (
  <i onClick={closeToast} className="la la-close notifications-close" />
);

let globalWebSocket = null; 
class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      telegramLink: "",
      wss: null,
    };
  }

  initWebsocket (){
    const ws = new WebSocket(`${process.env.REACT_APP_SOCKET_SERVER}`);
    globalWebSocket = ws; 
    ws.onopen = () => {
        console.log('Connected to WebSocket');
        // Send a message
        ws.send(JSON.stringify({ token: localStorage.getItem('accessToken') }));;
    };

    ws.onmessage = (event) => {
        try{
            console.log('Message from server:', event.data);
            let data = JSON.parse(event.data); 

          }catch(e){
            console.log(event.data); 
        }
    };
    ws.onerror = (error) => {
        console.error('WebSocket error:', error);
    };
    ws.onclose = () => {
        console.log('WebSocket connection closed');
        globalWebSocket = null; 
    };
  }
  componentDidMount() {
    APIServer.get(`/api/user/telegram`)
      .then((res) => {
        this.setState({ telegramLink: res.data.body });
      })
      .catch((err) => {});

      setInterval(()=>{
        if(!globalWebSocket){
          this.initWebsocket(); 
        }
      }, 5000)
  }

  render() {
    const { telegramLink } = this.state;
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar
          closeButton={<CloseButton />}
        />
        <Suspense fallback={<div></div>}>
          <Router>
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/login" />} />
              <PrivateRoute
                path="/app"
                dispatch={this.props.dispatch}
                location={this.props.location}
                component={LayoutComponent}
              />
              <Route path="/register" exact component={Register} />
              <Route path="/login" exact component={Login} />
              <Route path="/verify-email" exact component={VerifyEmail} />
              <Route path="/remind-link" exact component={ResetLink} />
              <Route path="/reset-password" exact component={ResetPassword} />
              <Route path="/error" exact component={ErrorPage} />
              <Route path="/ib/:ibLinkId" component={Referral} />
              <Route component={ErrorPage} />
              <Redirect from="*" to="/app/profile" />
            </Switch>
          </Router>
        </Suspense>
        <Box
          sx={{
            position: "fixed",
            float: "right",
            bottom: 16,
            right: 16,
            zIndex: 10,
          }}
        >
          <NavLink href={telegramLink} target="_blank">
            <Fab color="primary" className="btn__primary" aria-label="add">
              <MSGIcon />
            </Fab>
          </NavLink>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
